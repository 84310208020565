import {
  DUTY_MANAGEMENT,
  DUTY_MANAGEMENT_REPORTS_create,
  DUTY_MANAGEMENT_REPORTS_read,
  DUTY_MANAGEMENT_REPORTS_update,
  DUTY_MANAGEMENT_REPORTS_delete,
  DUTY_MANAGEMENT_ARCHIVE_read,
  DUTY_MANAGEMENT_CONFIG_read,
  // DUTY_MANAGEMENT_CONFIG_update,
  DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_create,
  DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_read,
  DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_update,
  DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_delete,
} from "@/data/permission-types.js";
export const dutyManagement = {
  id: `${DUTY_MANAGEMENT}`,
  name: "Dienstverwaltung",
  disabled: false,
  info: "",
  icon: "mdi-briefcase-edit",
  children: [
    {
      id: "dutyManagement.dutyReports",
      name: "Dienstberichte",
      disabled: false,
      chipsName: "Dienstberichte",
      info: "",
      icon: "",
      children: [
        {
          id: `${DUTY_MANAGEMENT_REPORTS_create}`,
          name: "Dienstbericht erstellen",
          disabled: false,
          chipsName: "Dienstverwaltung > Dienstberichte (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${DUTY_MANAGEMENT_REPORTS_read}`,
          name: "Dienstbericht lesen",
          disabled: false,
          chipsName: "Dienstverwaltung > Dienstberichte (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${DUTY_MANAGEMENT_REPORTS_update}`,
          name: "Dienstbericht bearbeiten",
          disabled: false,
          chipsName: "Dienstverwaltung > Dienstberichte (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${DUTY_MANAGEMENT_REPORTS_delete}`,
          name: "Dienstbericht löschen",
          disabled: false,
          chipsName: "Dienstverwaltung > Dienstberichte (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        // {
        //   id: `${DUTY_MANAGEMENT_REPORTS_read_all}`,
        //   name: "Alle Dienstberichte lesen",
        //   disabled: false,
        //   chipsName: "Dienstverwaltung > Dienstberichte (Alle lesen)",
        //   info: "",
        //   icon: "mdi-dots-horizontal-circle",
        // },
        // {
        //   id: `${DUTY_MANAGEMENT_REPORTS_manage_all}`,
        //   name: "Alle Dienstberichte verwalten",
        //   disabled: false,
        //   chipsName: "Dienstverwaltung > Dienstberichte (Alle verwalten)",
        //   info: "",
        //   icon: "mdi-dots-horizontal-circle",
        // },
      ],
    },
    {
      id: `${DUTY_MANAGEMENT_ARCHIVE_read}`,
      name: "Archiv",
      disabled: false,
      chipsName: "Dienstverwaltung > Archiv",
      info: "",
      dependencies: [`${DUTY_MANAGEMENT_REPORTS_read}`],
      icon: "",
    },
    {
      id: "DUTY_MANAGEMENT.konfiguration",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${DUTY_MANAGEMENT_CONFIG_read}`, //TODO:
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Dienstverwaltung > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        // {
        //   id: `${DUTY_MANAGEMENT_CONFIG_read}`,
        //   name: "Konfiguration lesen",
        //   disabled: true,
        //   chipsName: "Dienstverwaltung > Konfiguration > Allgemein (Lesen)",
        //   info: "",
        //   dependencies: [`${DUTY_MANAGEMENT_CONFIG_read}`],
        //   icon: "mdi-eye",
        // },
        // {
        //   id: `${DUTY_MANAGEMENT_CONFIG_update}`,
        //   name: "Konfiguration bearbeiten",
        //   disabled: true,
        //   chipsName: "Dienstverwaltung > Konfiguration > Allgemein",
        //   info: "",
        //   dependencies: [
        //     `${DUTY_MANAGEMENT_CONFIG_GENERAL_read}`,
        //     `${DUTY_MANAGEMENT_CONFIG_read}`,
        //   ],
        //   icon: "mdi-pencil",
        // },
        {
          id: "dutyManagement.config.dutyTypes",
          name: "Dienstarten",
          disabled: false,
          info: "",
          children: [
            {
              id: `${DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_create}`,
              name: "Dienstarten erstellen",
              disabled: false,
              chipsName:
                "Dienstverwaltung > Konfiguration > Dienstarten (Erstellen)",
              info: "",
              icon: "mdi-plus-circle",
            },
            {
              id: `${DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_read}`,
              name: "Dienstarten lesen",
              disabled: false,
              chipsName:
                "Dienstverwaltung > Konfiguration > Dienstarten (Lesen)",
              info: "",
              icon: "mdi-eye-circle",
            },
            {
              id: `${DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_update}`,
              name: "Dienstarten bearbeiten",
              disabled: false,
              chipsName:
                "Dienstverwaltung > Konfiguration > Dienstarten (Bearbeiten)",
              info: "",
              icon: "mdi-pencil-circle",
            },
            {
              id: `${DUTY_MANAGEMENT_CONFIG_DUTY_TYPES_delete}`,
              name: "Dienstarten löschen",
              disabled: false,
              chipsName:
                "Dienstverwaltung > Konfiguration > Dienstarten (Löschen)",
              info: "",
              icon: "mdi-delete-circle",
            },
          ],
        },
      ],
    },
  ],
};
